import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import 'slick-carousel';
import 'magnific-popup';
import ScrollReveal from 'scrollreveal';
import { atcb_action, atcb_init } from 'add-to-calendar-button';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSmoothScroll();
    this.initSliders();
    this.initAddToCal();
    this.initPopups();
    this.initAnims();
    this.initAccordion();
    this.initFaqPanels();
    this.formEdits();
    this.allyEdits();
    this.docsSearch();
    if ( $('body').hasClass('blog') ) {
      this.catFilterParams();
    }
    if ( $('body').hasClass('page-template-ways-to-save') ) {
      this.waystosaveTabbedLinks();
    }
    if ($('.js-countdown').length > 0) {
      this.countDown();
    }
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-mobile-nav li.menu-item-has-children > a', event => this.showMobileSubNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', 'a[href="#newsletter"]', event => this.closeMobileNav(event));
    this.$doc.on('click', '.option a.nturl', event => this.langSelectedClass(event));
    this.$doc.on('click', '.js-loadmore', event => this.loadMore(event));
    this.$doc.on('change', '.js-filter-posts', event => this.filterPosts(event));
    this.$doc.on('click', '.js-filter-cat', event => this.filterPostsCats(event));
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initAddToCal() {
    if ($('.atcb').length) {
      atcb_init();
    }
  },
  initSliders() {
    $('.js-hero-carousel').slick({
      infinite: true,
      speed: 1400,
      fade: true,
      cssEase: 'ease-out',
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: false,
      pauseOnHover: false
    });
    $('.js-carousel-playpause').on('click', function(e) {
      e.preventDefault();
      if ($(this).hasClass('paused')) {
        $('.js-hero-carousel').slick('slickPlay');
        $('.js-carousel-playpause').removeClass('paused');
        $('.js-carousel-play-svg').html('<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 0.333252C12.2667 0.333252 0.333374 12.2666 0.333374 26.9999C0.333374 41.7333 12.2667 53.6666 27 53.6666C41.7334 53.6666 53.6667 41.7333 53.6667 26.9999C53.6667 12.2666 41.7334 0.333252 27 0.333252ZM24.3334 37.6666H19V16.3333H24.3334V37.6666ZM35 37.6666H29.6667V16.3333H35V37.6666Z" fill="#FDCD06"/></svg>');
      } else {
        $('.js-hero-carousel').slick('slickPause');
        $('.js-carousel-playpause').addClass('paused');
        $('.js-carousel-play-svg').html('<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 0.333252C12.2667 0.333252 0.333374 12.2666 0.333374 26.9999C0.333374 41.7333 12.2667 53.6666 27 53.6666C41.7334 53.6666 53.6667 41.7333 53.6667 26.9999C53.6667 12.2666 41.7334 0.333252 27 0.333252ZM21.6667 38.9999V14.9999L37.6667 26.9999L21.6667 38.9999Z" fill="#FDCD06"/></svg>');
      }
    });
    $('.js-carousel').on('init', function(event, slick, direction){
      $('.js-carousel .slick-track').attr('id', 'carouselItems');
      if ( $( ".js-carousel .slick-next" ).length ) {
        $( ".js-carousel .slick-next" ).attr('aria-controls', 'carouselItems');
      }
      if ( $( ".js-carousel .slick-prev" ).length ) {
        $( ".js-carousel .slick-prev" ).attr('aria-controls', 'carouselItems');
      }
    });
    $('.js-carousel').slick({
      dots: true,
      arrows: true,
      adaptiveHeight: true,
      responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false
        }
      },
    ]
    });
  },
  initPopups() {
    $(document).magnificPopup({
      delegate: '.js-popup',
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate',
      callbacks: {
        open: function() {
          if ($(this.content).find('video').length ) {
            $(this.content).find('video')[0].play();
          }
        },
        close: function() {
          if ( $( ".js-internal-video" ).length ) {
            $('.js-internal-video').trigger('pause');
          }
        }
      }
    });
    $('.js-external-video-popup').magnificPopup({
      type: 'iframe',
      mainClass: 'popup--animate popup--iframe',
    });
  },
  initAccordion() {
    // $( '.js-accordion-content' ).hide();
    $( '.js-accordion-content' ).attr( 'id', function( IDcount ) {
      return 'panel-' + IDcount;
    });
    $( '.js-accordion-content' ).attr( 'aria-labelledby', function( IDcount ) {
      return 'control-panel-' + IDcount;
    });
    $( '.js-accordion-content' ).attr( 'hidden', 'true' );
    $( '.js-accordion-list .js-accordion-content' ).attr( 'role' , 'region' );
    $( '.js-accordion-title' ).each(function(i){
      var $target = $(this).next( '.js-accordion-content' )[0].id;
      var $link = $( '<button>', {
        'aria-expanded': 'false',
        'aria-controls': $target,
        'id' : 'control-' + $target
      });
      $(this).wrapInner($link);
    });

    $( '.js-accordion-title button' ).append('<span class="icon"><svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.1926 0.5C11.911 0.5 12.2703 1.36791 11.7617 1.87653L6.57106 7.07123C6.25625 7.38583 5.74366 7.38583 5.42884 7.07123L0.238221 1.87653C-0.27036 1.36791 0.0888635 0.5 0.807311 0.5H11.1926Z" fill="black"/></svg></span>');

    $( '.js-accordion-list .js-accordion-title:first-of-type button' ).each(function() {
      $(this).parents( '.js-accordion-list' ).find( '[aria-expanded=true]' ).attr( 'aria-expanded' , false ).removeClass( 'active' ).parent().next( '.js-accordion-content' ).removeClass( 'active' ).attr( 'hidden', 'true' );
      $(this).attr( 'aria-expanded' , true ).addClass( 'active' ).parent().next( '.js-accordion-content' ).addClass( 'active' ).removeAttr( 'hidden');
    });

    $( '.js-accordion-title button' ).click(function() {
      if ($(this).attr( 'aria-expanded' ) == 'false'){
        var $href = $(this).attr('aria-controls');
        $(this).parents( '.js-accordion-list' ).find( '[aria-expanded=true]' ).attr( 'aria-expanded' , false ).removeClass( 'active' ).parent().next( '.js-accordion-content' ).removeClass( 'active' ).attr( 'hidden', 'true');
        $(this).attr( 'aria-expanded' , true ).addClass( 'active' ).parent().next( '.js-accordion-content' ).addClass( 'active' ).removeAttr( 'hidden');
        $(this).parents('.accordion__main').find('.js-accordion-image').removeClass('active');
        $(this).parents('.accordion__main').find('.js-accordion-image[data-accordion-image="'+$href+'"]').addClass('active');
        $('html,body').animate({
          scrollTop: $(this).offset().top - 170
        }, 1000);
      } else {
      }
      return false;
    });
  },
  initFaqPanels() {
    if ( $( ".faq__main" ).length ) {
      $(".js-faq-panel [role='tab']").click(function(e) {
        e.preventDefault();
        $(this).attr("aria-selected", "true");
        $(this).parent().siblings().children().attr("aria-selected", "false");
        $(this).attr("tabindex", "0");
        $(this).parent().siblings().children().attr("tabindex", "-1");
        var tabpanelShow = $(this).attr("href");
        $(tabpanelShow).attr("aria-hidden", "false");
        $(tabpanelShow).attr("tabindex", "0");
        $(tabpanelShow).siblings().attr("aria-hidden", "true");
        $(tabpanelShow).siblings().attr("tabindex", "-1");
      });

      $('.add-value__hero a[href*=\\#]').on('click', function (event) {
        if (this.pathname === window.location.pathname) {
          event.preventDefault();
          let tabpanelShow = $(this).attr("href");
          let tab = $('.js-faq-panel a[href*="'+tabpanelShow+'"]');
          tab.attr("aria-selected", "true");
          tab.parent().siblings().children().attr("aria-selected", "false");
          tab.attr("tabindex", "0");
          tab.parent().siblings().children().attr("tabindex", "-1");
          tab.focus();
          $(tabpanelShow).attr("aria-hidden", "false");
          $(tabpanelShow).siblings().attr("aria-hidden", "true");
          $(tabpanelShow).attr("tabindex", "0");
          $(tabpanelShow).siblings().attr("tabindex", "-1");
          $([document.documentElement, document.body]).animate({
            scrollTop: $(".accordion__main").offset().top - 150
          }, 500);

          let tabNum = tabpanelShow.replace('#desktop-topic-', '') - 1;
          let acc = $('#control-panel-'+tabNum);

          if (acc.attr('aria-expanded') == 'false') {
            var $href = acc.attr('aria-controls');
            acc.parents('.js-accordion-list').find('[aria-expanded=true]').attr('aria-expanded', false).removeClass('active').parent().next('.js-accordion-content').slideUp(300).attr('hidden', 'true');
            acc.attr('aria-expanded', true).addClass('active').parent().next('.js-accordion-content').slideDown(300).removeAttr('hidden');
            acc.parents('.accordion__main').find('.js-accordion-image').removeClass('active');
            acc.parents('.accordion__main').find('.js-accordion-image[data-accordion-image="' + $href + '"]').addClass('active');
          } else {
          }
        }
      });

      $('.js-faq-panel select').on('change', function() {
        var tabpanelShow = '#'+this.value;
        $(tabpanelShow).attr("aria-hidden", "false");
        $(tabpanelShow).siblings().attr("aria-hidden", "true");
        $(tabpanelShow).attr("tabindex", "0");
        $(tabpanelShow).siblings().attr("tabindex", "-1");
      });

      const tabs = document.querySelectorAll('.js-faq-panel a[role="tab"]');
      const tabList = document.querySelector('.js-faq-panel ul[role="tablist"]');

      // Enable arrow navigation between tabs in the tab list
      let tabFocus = 0;

      tabList.addEventListener("keydown", e => {
        // Move right
        if (e.keyCode === 39 || e.keyCode === 37) {
          tabs[tabFocus].setAttribute("tabindex", -1);
          if (e.keyCode === 39) {
            tabFocus++;
            // If we're at the end, go to the start
            if (tabFocus >= tabs.length) {
              tabFocus = 0;
            }
            // Move left
          } else if (e.keyCode === 37) {
            tabFocus--;
            // If we're at the start, move to the end
            if (tabFocus < 0) {
              tabFocus = tabs.length - 1;
            }
          }

          tabs[tabFocus].setAttribute("tabindex", 0);
          tabs[tabFocus].focus();
        }
      });
    }
  },
  formEdits() {
    $(".tnp-email").attr("placeholder", "Email");
    $(".tnp-email").attr("ID", "tnp-email");
  },
  allyEdits() {
    $('input.weglot_choice').attr('tabindex', '-1');
    $('.js-mobile-nav li.menu-item-has-children > a').append('<span class="u-sr-only"> - Expand Subnavigation Menu</span>');
  },
  docsSearch() {
    $('.js-documents-dropdown').on('click', function (event) {
      let el = $(this);
      if (el.attr('aria-expanded') === "false") {
        el.attr('aria-expanded', 'true');
        el.parent().siblings('.js-documents-dropdown-content').slideDown(300);
      } else {
        el.attr('aria-expanded', 'false');
        el.parent().siblings('.js-documents-dropdown-content').slideUp(300);
      }
    });
    function ajaxLoadDocs(page = 1, agendas = '', topics='', date='', search='', order='DESC') {
      const data = {
        page: page,
        agendas: agendas,
        topics: topics,
        date: date,
        search: search,
        order: order,
        action: 'ajax_posts_filter'
      };

      $.ajax({
        url: php_vars.ajax_url,
        type: "POST",
        dataType: 'html',
        data,
        beforeSend: function () {
          /* $('html, body').animate({
            scrollTop: $('#js-documents-list').offset().top - 450
          }, 100); */
          $('body').find('#js-documents-list').html('');
          $('.js-documents-no').html('');
          $('.documents__cats').hide();
          $('.documents__results').show();
          $('.js-documents-showlanding').show();
          $('.js-documents-loader').show();
        },
        success: function (htmlResponse) {
          if (htmlResponse == "" || htmlResponse == null) {
            $('.js-documents-no').html('0 Results');
            $('body').find('#js-documents-list').html('<p class="no-results">No results found, please try a different combination of filters.</p>');
          }
          else {
            $('body').find('#js-documents-list').html(htmlResponse);
            let $results = $('#js-num-results').data('results');
            if ( $results == 1 ) {
              $('.js-documents-no').html($results + ' Result');
            } else {
              $('.js-documents-no').html($results + ' Results');
            }
          }
        },
        error: function () {
          $('body').find('#js-documents-list').html('<p class="no-results">No results found.</p>');
        },
        complete: function () {
          $('body').find('.js-documents-loader').hide();
        }
      });
    }
    if ($('body').hasClass('page-template-documents')) {
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('date')) {
          let $date = searchParams.get('date');
          let $date_formatted = $date.substring(0, 4) + '-' + $date.substring(4, 6) + '-' + $date.substring(6, 8);
          $(window).on('load', function () {
            ajaxLoadDocs(1, '', '', $date, '', 'DESC');
            $('#datedropdown input').val($date_formatted);
          });
        }
      }
      $('#docs-order-filter').on('change', function () {
        let $agendas = $("input[name='agenda']:checked").val();
        if ($agendas == 'allagendas') {
          $agendas = '';
        }
        let $topics = $("input[name='topic']:checked").val();
        if ($topics == 'alltopics') {
          $topics = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        let $order = $('#docs-order-filter').val();
        ajaxLoadDocs(1, $agendas, $topics, $date, $search, $order);
      });
      $(document).on('click', '.js-documents-filter', function (event) {
        event.preventDefault();
        let $agendas = $("input[name='agenda']:checked").val();
        if ( $agendas == 'allagendas' ) {
          $agendas = '';
        }
        let $topics = $("input[name='topic']:checked").val();
        if ( $topics == 'alltopics' ) {
          $topics = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        let $order = $('#docs-order-filter').val();
        ajaxLoadDocs(1, $agendas, $topics, $date, $search, $order);
      });
      $(document).on('change', '.js-document-input', function (event) {
        event.preventDefault();
        let $agendas = $("input[name='agenda']:checked").val();
        if ($agendas == 'allagendas') {
          $agendas = '';
        }
        let $topics = $("input[name='topic']:checked").val();
        if ($topics == 'alltopics') {
          $topics = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        let $order = $('#docs-order-filter').val();
        ajaxLoadDocs(1, $agendas, $topics, $date, $search, $order);
      });
      $(document).on('click', '.js-agenda-filter', function (event) {
        event.preventDefault();
        let $agendas = $(this).data('agenda');
        $("input[name=agenda]").prop("checked", false);
        $("input[name=agenda][value="+$agendas+"]").prop("checked", true);
        let $topics = $("input[name='topic']:checked").val();
        if ($topics == 'alltopics') {
          $topics = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        ajaxLoadDocs(1, $agendas, $topics, $date, $search, 'DESC');
        $('#agendasdropdowntrigger').attr('aria-expanded', 'true');
        $('#agendasdropdown').slideDown();
      });
      $(document).on('click', '.js-topic-filter', function (event) {
        event.preventDefault();
        let $topics = $(this).data('topic');
        $("input[name=topic]").prop("checked", false);
        $("input[name=topic][value=" + $topics + "]").prop("checked", true);
        let $agendas = $("input[name='agenda']:checked").val();
        if ($agendas == 'allagendas') {
          $agendas = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        ajaxLoadDocs(1, $agendas, $topics, $date, $search, 'DESC');
        $('#topicsdropdowntrigger').attr('aria-expanded', 'true');
        $('#topicsdropdown').slideDown();
      });
      $(document).on('click', '.js-ajax-pagination a', function (event) {
        event.preventDefault();
        let $page = parseInt($(this).attr('href').replace(/\D/g, ''));
        let $agendas = $("input[name='agenda']:checked").val();
        if ($agendas == 'allagendas') {
          $agendas = '';
        }
        let $topics = $("input[name='topic']:checked").val();
        if ($topics == 'alltopics') {
          $topics = '';
        }
        let $date = $('#datedropdown input').val().replace(/-/g, "");
        let $search = $('#documents-search').val();
        let $order = $('#docs-order-filter').val();
        ajaxLoadDocs($page, $agendas, $topics, $date, $search, $order);
      });
      $(document).on('click', '.js-documents-filterreset', function (event) {
        event.preventDefault();
        $("input[name=agenda]").prop("checked", false);
        $("input[name=agenda][value='allagendas']").prop("checked", true);
        $("input[name=topic]").prop("checked", false);
        $("input[name=topic][value='alltopics']").prop("checked", true);
        $('#datedropdown input').val('');
        let $order = $('#docs-order-filter').val();
        let $search = $('#documents-search').val();
        if ( $(this).hasClass('js-documents-showall') ) {
          $('#documents-search').val('');
          $search = '';
        }
        ajaxLoadDocs(1, '', '', '', $search, $order);
      });
      $(document).on('click', '.js-documents-showlanding', function (event) {
        event.preventDefault();
        $("input[name=agenda]").prop("checked", false);
        $("input[name=agenda][value='allagendas']").prop("checked", true);
        $("input[name=topic]").prop("checked", false);
        $("input[name=topic][value='alltopics']").prop("checked", true);
        $('#datedropdown input').val('');
        $('#docs-order-filter').val('DESC');
        $('#documents-search').val('');
        ajaxLoadDocs(1, '', '', '', '', 'DESC');
        $('.documents__cats').show();
        $('.documents__results').hide();
        $('.js-documents-showlanding').hide();
      });
    }
  },
  initAnims() {
    ScrollReveal().reveal('.js-fadeleft', { duration: 600, distance: '60px', origin: 'right', easing: 'ease-out' });
    ScrollReveal().reveal('.js-fadeleft--300', { duration: 600, delay: 300, distance: '60px', origin: 'right', easing: 'ease-out' }, );
    ScrollReveal().reveal('.js-fadeleft--600', { duration: 600, delay: 600, distance: '60px', origin: 'right', easing: 'ease-out' });
    ScrollReveal().reveal('.js-fadeleft--900', { duration: 600, delay: 900, distance: '60px', origin: 'right', easing: 'ease-out' });
    ScrollReveal().reveal('.js-fadeup', { duration: 600, distance: '60px', origin: 'bottom', easing: 'ease-out', viewOffset: {
      top: 200
    } });
    ScrollReveal().reveal('.js-fade', { duration: 600, easing: 'ease-out', viewOffset: {
      top: 200
    } });
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  initSmoothScroll() {
    // https://css-tricks.com/snippets/jquery/smooth-scrolling/
    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.js-popup')
    .not('.js-tab')
    .not('.add-value__hero .button')
    .click(function(event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 200
          }, 500, function() {
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            } else {
              $target.attr('tabindex','-1');
              $target.focus();
            };
          });
        }
      }
    });

    this.$doc.ready ( function($) {
      var hash= window.location.hash
      if ( hash == '' || hash == '#' || hash == undefined ) return false;
      var target = $(hash);

      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').stop().animate({
          scrollTop: target.offset().top - 200
        }, 500 );
      }
    });

  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  showMobileSubNav(event) {
    event.preventDefault();
    $(event.currentTarget).parent().toggleClass('is-active');
    if ($(event.currentTarget).parent().hasClass('is-active')) {
      $(event.currentTarget).parent().attr('aria-pressed', 'true');
    } else {
      $(event.currentTarget).parent().removeAttr('aria-pressed');
    }
  },
  closeMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.removeClass('is-active');
    this.$hamburger.removeClass('is-active');
    this.$hamburger.removeAttr('aria-pressed');
  },
  langSelectedClass(event) {
    let el = $(event.currentTarget);
    el.siblings().removeClass('selected');
    el.addClass('selected');
  },
  loadMore(event) {
    function ajaxLoadMore(page = 2, category = '', order = '') {
      if ($('#queryinfo').length) {
        max_pages = $('#queryinfo').data('maxpages');
      }
      let button = $(event.currentTarget),
        data = {
          'action': 'loadmore',
          'page': page,
          'category': category,
          'order': order,
        };
      $.ajax({
        url: ajaxurl,
        type: "POST",
        dataType: 'html',
        data,
        beforeSend: function () {
          button.text('Loading...');
        },
        success: function (data) {
          if (data) {
            button.text('More News');
            $(".js-posts").append(data);
            ScrollReveal().sync();
            page++;
            $('#nextpage').val(page);
            if (page > max_pages) {
              button.parent().hide();
              button.attr('disabled', true);
            }
          } else {
            button.parent().hide();
            button.attr('disabled', true);
          }
        }
      });
    }
    let $category = $('#category-filter').val();
    if ($category == 'all') {
      $category = '';
    }
    let $order = $('#order-filter').val();
    let $page = $('#nextpage').val();

    ajaxLoadMore($page, $category, $order);
  },
  filterPosts() {
    function ajaxLoadPosts(page = 1, category = '', order = '') {
      let button = $('.js-loadmore');
      const data = {
        page: page,
        category: category,
        order: order,
        action: 'loadmore'
      };

      $.ajax({
        url: ajaxurl,
        type: "POST",
        dataType: 'html',
        data,
        beforeSend: function () {
          button.parent().hide();
          $('.js-posts').addClass('loading');
          page = 1;
        },
        success: function (htmlResponse) {
          if (htmlResponse) {
            button.text('More News');
            $('.js-posts').removeClass('loading');
            $(".js-posts").html(htmlResponse);
            ScrollReveal().sync();
            page++;
            $('#nextpage').val(2);
            if ($('#queryinfo').length) {
              max_pages = $('#queryinfo').data('maxpages');
            }
            if (page > max_pages) {
              button.parent().hide();
              button.attr('disabled');
            } else {
              button.parent().show();
              button.removeAttr('disabled');
            }
          } else {
            button.parent().hide();
            button.attr('disabled', true);
          }
        }
      });
    }
    let $category = $('#category-filter').val();
    if ( $category == 'all' ) {
      $category = '';
    }
    let $order = $('#order-filter').val();
    ajaxLoadPosts(1, $category, $order);
  },
  filterPostsCats(event) {
    event.preventDefault();
    let dropDownVal = event.currentTarget.getAttribute('href');
    if ( $('body').hasClass('blog') ){
      $('#category-filter').val(dropDownVal);
      $('#category-filter').change();
    } else {
      window.location.href = '/about-orca/news/?cat='+dropDownVal;
    }
  },
  catFilterParams() {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('cat')) {
        let cat = searchParams.get('cat');
        $('#category-filter').val(cat);
        setTimeout(function () { $('#category-filter').change() }, 100);
      }
    }
  },
  countDown() {

    var countDownDate = new Date(acfDate).getTime();

    var x = setInterval(function() {

      var now = new Date().getTime();

      var distance = countDownDate - now;

      var months = Math.floor((distance % (1000 * 60 * 60 * 24 * 30 * 12)) / (1000 * 60 * 60 * 24 * 30));
      var days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
        document.getElementById("count").innerHTML = "<div class='months'>" + "<div class='number'>" + 0 + "</div>" + "<div class='duration'>MONTHS</div></div>" + "<div class='days'><div class='number'>" + 0 + "</div><div class='duration'>DAYS</div></div>" + "<div class='hours'><div class='number'>" + 0 + "</div><div class='duration'>HOURS</div></div>" + "<div class='minutes'><div class='number'>" + 0 + "</div><div class='duration'>MINUTES</div></div>";
      } else {
        document.getElementById("count").innerHTML = "<div class='months'>" + "<div class='number'>" + months + "</div>" + "<div class='duration'>MONTHS</div></div>" + "<div class='dot'>:</div>" + "<div class='days'><div class='number'>" + days + "</div><div class='duration'>DAYS</div></div>" + "<div class='dot'>:</div>" + "<div class='hours'><div class='number'>" + hours + "</div><div class='duration'>HOURS</div></div>" + "<div class='dot'>:</div>" +"<div class='minutes'><div class='number'>" + minutes + "</div><div class='duration'>MINUTES</div></div>";
      }
    }, 1000);
  },
  waystosaveTabbedLinks() {
    $(document).ready(function() {
      var target = $('.wts__main')
      $('html, body').animate({
          scrollTop: target.offset().top - 100
      }, 1000);
    });
    $( '.js-sidenav' ).each(function(i){
      var Link = $(this).attr("href");
      if(Link === window.location.href){
        $(this).addClass('is-active')
        var Color = $(this).data('color');
        $('.wts__main').addClass(Color);
      }
    });
  }
};

Mangrove.init();
